/* Import Fonts  */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Montserrat:wght@400;500;600;700&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body {
	margin: 0;

	font-family: -apple-system, BlinkMacSystemFont, "Montserrat", "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: 100vh;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* Fonts Faces Definition */
@font-face {
	font-family: "Tomato Grotesk";
	src: url("assets/fonts/TomatoGrotesk/TomatoGrotesk-Regular.ttf")
		format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Tomato Grotesk";
	src: url("assets/fonts/TomatoGrotesk/TomatoGrotesk-Medium.ttf")
		format("truetype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Tomato Grotesk";
	src: url("assets/fonts/TomatoGrotesk/TomatoGrotesk-SemiBold.ttf")
		format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Tomato Grotesk";
	src: url("assets/fonts/TomatoGrotesk/TomatoGrotesk-Bold.ttf")
		format("truetype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Tomato Grotesk";
	src: url("assets/fonts/TomatoGrotesk/TomatoGrotesk-ExtraBold.ttf")
		format("truetype");
	font-weight: 900;
	font-style: normal;
}
